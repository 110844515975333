<template>
  <v-layout style="margin: 0px; padding: 0px; width: 100%;">
    <v-row>
      <v-col col="12" md="5">
        <v-row wrap>
          <v-col cols="8" offset="2">
            <v-row>
              <v-col cols="10">
                <router-link to="/" style="text-decoration: none;color: black;">
                  <v-icon>mdi-arrow-left-circle-outline</v-icon>
                  <span class="ml-5"> Return to Home </span>
                </router-link>
              </v-col>
            </v-row>

            <v-row style="margin-top:30px;">
              <v-col cols="12" align="center">
                <router-link to="/">
                  <v-img
                    class="centerImg2"
                     :src="'img/logo.png'"
                  />
                </router-link>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" align="center" justify="center">
                <span class="titleStyleColor">Forgot</span>
                <span class="titleStyle"> Password</span><br />
                <router-link
                  :to="{ name: 'pharmaRegistration' }"
                  style="text-decoration: none; color: black;"
                >
                  <v-icon>mdi-account-lock-outline</v-icon>
                  <span style="color:#7CBF46"> Don't have an account?</span>
                </router-link>
              </v-col>
            </v-row>

            <v-row class="mt-3">
              <v-col cols="12" align="center" justify="center">
                <span>
                  Hello! We heard you lost your password. Not to worry! please
                  input your email below to change password.</span
                >
              </v-col>
            </v-row>

            <v-dialog v-model="modalSuccess" persistent max-width="350">
              <v-card style="border-radius:15px;height:auto; padding:10px;">
                <v-row wrap>
                  <v-col cols="12" align="center" justify="center">
                    <v-icon style="color:#456928; font-size:42px;"
                      >mdi-checkbox-marked-circle-outline</v-icon
                    >
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col cols="12" align="center" justify="center">
                    <h4>Email Sent</h4>
                    <p>
                      A password reset link has been sent to your email
                      <b>{{ this.formData.email }}</b>
                    </p>
                  </v-col>
                </v-row>

                <v-row wrap>
                  <v-col cols="12" align="center" justify="center">
                    <v-btn block dark color="#456928" @click="redirectLogin">
                      OK
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-dialog>

            <v-row>
              <v-progress-linear
                color="#7CBF46"
                v-show="loader"
                :indeterminate="true"
              ></v-progress-linear>
              <v-col cols="12">
                <v-row>
                  <v-text-field
                    v-model="formData.email"
                    label="Email"
                    append-icon="mdi-email"
                    outlined
                    dense
                    color="#7CBF46"
                  ></v-text-field>
                </v-row>

                <v-layout color="#BD2121">
                  <h5 class="text-danger" align="center">
                    {{ errorMessage }}
                  </h5>
                </v-layout>

                <v-row>
                  <v-col col="12" align="center" justify="center">
                    <v-btn
                      align="center"
                      class="buttonStyle"
                      @click.native="signin"
                      block
                    >
                      Send Link
                    </v-btn>
                  </v-col>
                </v-row>

                <!-- <v-row>
                  <v-col cols="12" align="center" justify="center">
                    <h6 class="mt-5">
                      Or Sign in with
                      <v-icon>
                        mdi-hand-pointing-down
                      </v-icon>
                    </h6>
                  </v-col>
                </v-row> -->
<!-- 
                <v-row>
                  <v-col cols="3">
                    <v-btn color="primary" fab small dark>
                      <v-icon>mdi-facebook</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="3">
                    <v-btn color="error" fab small dark>
                      <v-icon>mdi-google</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="3">
                    <v-btn color="primary" fab small dark>
                      <v-icon>mdi-twitter</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="3">
                    <v-btn color="secondary" fab small dark>
                      <v-icon>mdi-linkedin</v-icon>
                    </v-btn>
                  </v-col>
                </v-row> -->
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col col="12" md="7" class="loginPadding hidden-sm-and-down">
        <div class="overlay"></div>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
export default {
  name: "ResetPasswordLink",

  data: () => ({
    loader: false,
    checkbox: false,
    modalSuccess: false,
    errorMessage: "",
    show3: false,
    formData: {
      email: "",
    },
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
    },
  }),
  methods: {
    signin() {
      this.loader = true;
      this.errorMessage = "";
      this.$http
        .post(`${process.env.VUE_APP_URL}password/send`, this.formData,{
          headers: {
            'apiKey':`${process.env.VUE_APP_KEY}`
          }
        })
        .then((response) => {
          if (response.data.success == true) {
            this.modalSuccess = true;
            this.loader = false;
          }
        })
        .catch((error) => {
          this.loader = false;
          this.errorMessage = error.response.data.data;
        });
    },
    redirectLogin() {
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.overlay {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
}

.loginPadding {
  margin: 0px !important;
  padding: 0px !important;
  background: url("https://res.cloudinary.com/ecrtechdev/image/upload/v1632485906/loginImage_blv0va.png")
    no-repeat center right fixed !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
}

.titleStyle {
  width: 411px;
  font-family: ABeeZee;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
}

.titleStyleColor {
  width: 411px;
  font-family: ABeeZee;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  background: -webkit-linear-gradient(#72bd61, #111212);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.centerImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
  text-align: center;
}

.centerImg2 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  text-align: center;
}

.buttonStyle {
  width: auto;
  height: auto;
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 14px;
}
</style>
